<template>
  <footer>
    <!-- <div class="copyright">ChargeMe © 2022 Copyright</div> -->
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
// footer {
//   height: 3vh;
//   background-color: #949494;
//   text-align: center;
//   line-height: 3vh;
//   .copyright {
//     color: #fff;
//   }
// }
</style>
