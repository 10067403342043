<template>
  <div class="loading-screen" v-if="isLoading">
    <div class="logo">
      <el-image
        style="width: 40px; height: 40px"
        src="https://chargemestorage.blob.core.windows.net/chargeme-image/喬米LOGO (4).png"
        fit="fill"
      ></el-image>
    </div>
    <div class="text fs-3 mt-3">
      &nbsp;&nbsp;&nbsp;Loading<span class="ellipsis">.</span
      ><span class="ellipsis">.</span><span class="ellipsis">.</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: Boolean,
  },
};
</script>

<style scoped>
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.logo {
  animation: bounce 1s ease infinite, rotate 2s linear infinite;
}

.water-effect {
  background: linear-gradient(90deg, transparent, #0073e6, transparent);
  background-size: 200% 100%;
  animation: waterFlow 2s linear infinite;
  width: 100px;
  height: 20px;
}

.ellipsis {
  animation: blink 1s infinite;
  margin-left: 5px;
}

@keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
