import Storage from "../models/storage";
import AuthService from "../services/auth.service";

const user = Storage.get("user");
const initialState = user ? { status: { loggedIn: true }, user } : { status: { loggedIn: false }, user: null };
export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        user => {
          commit("loginSuccess", user);
          return Promise.resolve(user);
        },
        error => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit("logout");
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        response => {
          commit("loginSuccess", response);
          return Promise.resolve(response.data);
        },
        error => {
          commit("registerFailure");
          return Promise.reject(error);
        }
      );
    },
    lineLogin({ commit }, authCode) {
      return AuthService.lineLogin(authCode).then(
        Response => {
          commit("loginSuccess", Response);
          console.log(Response);
          return Promise.resolve(Response);
        },
        error => {
          if (user == null) commit("registerFailure");
          return Promise.reject(error);
        }
      );
    },
    googleLogin({ commit }, authCode) {
      return AuthService.googleLogin(authCode).then(
        Response => {
          commit("loginSuccess", Response);
          console.log(Response);
          return Promise.resolve(Response);
        },
        error => {
          commit("registerFailure");
          return Promise.reject(error);
        }
      );
    },
    faceBookLogin({ commit }, authCode) {
      return AuthService.faceBookLogin(authCode).then(
        Response => {
          commit("loginSuccess", Response);
          console.log(Response);
          return Promise.resolve(Response);
        },
        error => {
          commit("registerFailure");
          return Promise.reject(error);
        }
      );
    },
    bindGoogleLogin({ commit }, authCode) {
      return AuthService.googleLogin(authCode).then(
        Response => {
          commit("loginSuccess", Response);
          return Promise.resolve(Response);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    bindFaceBookLogin({ commit }, authCode) {
      return AuthService.faceBookLogin(authCode).then(
        Response => {
          commit("loginSuccess", Response);
          return Promise.resolve(Response);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    refreshToken({ commit }, response) {
      let user = AuthService._processResponse(response);
      if (user === null) commit("loginFailure");
      else commit("loginSuccess", user);
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
  },
};
