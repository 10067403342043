import authHeader from "../services/auth-header";
import store from "../store/index";
import AuthService from "../services/auth.service";
//服务器地址

export default {
  install(Vue) {
    Vue.mixin({
      methods: {
        fetchAPI: function (method, url, request = {}, isLoading = true) {
          if (isLoading && store.state.classRoomTabActiveName != "second") {
            store.commit("setIsLoading", true);
          }
          return this.axios({
            method: method,
            url: url,
            headers: authHeader(),
            data: request,
          })
            .then(response => {
              if (isLoading) {
                store.commit("setIsLoading", false);
              }
              return response;
            })
            .catch(err => {
              if (isLoading) {
                store.commit("setIsLoading", false);
              }
              if (err.response.data.Status == 2000) this.logoutCommon();
              return Promise.reject(err);
            });
        },
        turnBirthdayType: function (birthday) {
          if (birthday === "") return null;
          else if (birthday === null) return "";
          else return birthday;
        },
        notify: function (type, message, htmlString = false, duration = 1500, position = "top-right") {
          let title;
          if (type === "success") {
            title = "成功";
          } else if (type === "info") {
            title = "消息";
          } else if (type === "warning") {
            title = "警告";
          } else {
            title = "失敗";
          }
          this.$notify({
            title: title,
            message: message,
            type: type,
            duration: duration,
            position: position,
            dangerouslyUseHTMLString: htmlString,
          });
        },
        currencyFormat: function (num) {
          if (num === null) return;
          const parts = num.toString().split(".");
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return parts.join(".");
        },
        getInstrument(container = "default") {
          const vm = this;
          vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/Instruments`)
            .then(res => {
              if (res.data.length !== 0) {
                res.data.forEach(item => {
                  let temp = [];
                  let arr = [];
                  Object.assign(arr, item.instruments);
                  arr.forEach(x => {
                    temp.push({ value: x.id, label: x.name });
                    vm.$store.state.common.simpleInstrumentOptions.push({ value: x.id, label: x.name });
                  });
                  if (container == "default") container = vm.$store.state.common.instrumentOptions;
                  else if (container == "teacherClass") container = vm.form_options.instrument;
                  container.push({
                    label: item.instrumentType == "WesternMusic" ? "西樂" : item.instrumentType == "ChineseMusic" ? "中樂" : "其他",
                    children: temp,
                  });
                });
              }
            })
            .catch(err => {
              console.log(err);
            });
        },
        getDistrict() {
          const vm = this;
          vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/District`)
            .then(res => {
              if (res.data.length !== 0) {
                res.data.forEach(item => {
                  let temp = [];
                  let arr = [];
                  Object.assign(arr, item.district);
                  arr.forEach(x => {
                    temp.push({ value: x.id, label: x.districtName });
                    vm.$store.state.common.simpleAreaOptions.push({ value: x.id, label: x.districtName, county: item.county });
                  });
                  vm.$store.state.common.teachingAreaOptions.push({
                    label: item.county,
                    children: temp,
                  });
                });
              }
            })
            .catch(err => {
              console.log(err);
            });
        },
        getTeachingType() {
          const vm = this;
          vm.$store.state.common.teachingTypeOptions = [
            {
              value: "Online",
              label: "遠端",
            },
            {
              value: "Onsite",
              label: "現場",
            },
          ];
        },
        getInstrumentType() {
          const vm = this;
          vm.$store.state.common.instrumentTypeOptions = [
            {
              value: "WesternMusic",
              label: "西樂",
            },
            {
              value: "ChineseMusic",
              label: "中樂",
            },
            {
              value: "Others",
              label: "其他",
            },
          ];
        },
        validateEmpty(rule, value, callback) {
          if (value === "" || value === undefined || value.length === 0) {
            callback(new Error("此為必填資訊"));
          } else {
            callback();
          }
        },
        checkArrayNull(arr) {
          if (arr[0] == "") return [];
          return arr;
        },
        changeDot(arr, isLast = true) {
          return isLast ? arr.map(x => x[x.length - 1]).join(",") : arr.map(x => x[0]).join(",");
        },
        toTurnArrayType(arr, isNumber = false) {
          if (arr.length != 0 && typeof arr[0] != "object") return isNumber ? arr : arr.map(x => x.toString());
          return isNumber ? arr.map(x => x[x.length - 1]) : arr.map(x => x[x.length - 1].toString());
        },
        toTurnInstrument(instrumentId) {
          if (typeof instrumentId == "number") return instrumentId;
          else return instrumentId[instrumentId.length - 1];
        },
        imageRestrict(param) {
          const arr = ["image/jpeg", "image/jpg", "image/png"];
          let isQualified = arr.some(substring => param.file.type.includes(substring));
          return isQualified;
        },
        getDegree(data) {
          let temp = "";
          switch (data) {
            case "Beginner": {
              temp = "入門";
              break;
            }
            case "Elementary": {
              temp = "初階";
              break;
            }
            case "Intermediate": {
              temp = "中階";
              break;
            }
            case "Advance": {
              temp = "高階";
              break;
            }
            case "Master": {
              temp = "頂級";
              break;
            }
          }
          return temp;
        },
        turnTeachingType(data) {
          let temp = "";
          switch (data) {
            case "Online": {
              temp = "遠端";
              break;
            }
            case "Onsite": {
              temp = "現場";
              break;
            }
            default: {
              temp = "遠端/現場";
              break;
            }
          }
          return temp;
        },
        resetTeacherClassForm() {
          this.$store.state.classForm = {
            courseId: 0,
            color: null,
            isExperience: undefined,
            name: "",
            cover: "",
            introVideo: "",
            introduction: "",
            detail: "",
            status: "",
            instrumentId: 0,
            teacherId: 0,
            price: 0,
            priceRemark: "",
            degree: "",
            payPeriod: "",
            payMethod: [],
            payDiscount: [],
            teachingTypes: [],
            regions: [],
            isAddNewClass: true,
            duration: 0,
            maxStudentCount: 0,
            dateRange: [],
            courseOpenedTimeInfo: {
              startTime: null,
              endTime: null,
              availableWeekList: [],
            },
            courseTimeEntities: [],
          };
        },
        imageProcess(url, type = "avatar") {
          const vm = this;
          if (url == "") return type == "avatar" ? vm.$store.state.default.avatar : vm.$store.state.default.cover;
          else if (process.env.NODE_ENV === "production" && url.includes("localhost")) return type == "avatar" ? vm.$store.state.default.avatar : vm.$store.state.default.cover;
          else return url;
        },
        changeCreateTime(val) {
          if (process.env.NODE_ENV === "development") {
            return `${val.split(" ")[0]} ${val.split(" ")[2].split(":")[0]}:${val.split(" ")[2].split(":")[1]}`;
          } else {
            var today = new Date(`${val.split(" ")[0].split("/")[2]}/${val.split(" ")[0].split("/")[0]}/${val.split(" ")[0].split("/")[1]} ${val.split(" ")[1].split(":")[0]}:${val.split(" ")[1].split(":")[1]}`);
            today.setHours(today.getHours() + 8);
            return `${today.getFullYear()}/${today.getMonth() + 1}/${today.getDate()} ${today.getHours()}:${today.getMinutes()}`;
          }
        },
        getWeekTime(data) {
          let temp = "";
          switch (data) {
            case "星期一": {
              temp = "Monday";
              break;
            }
            case "星期二": {
              temp = "Tuesday";
              break;
            }
            case "星期三": {
              temp = "Wednesday";
              break;
            }
            case "星期四": {
              temp = "Thursday";
              break;
            }
            case "星期五": {
              temp = "Friday";
              break;
            }
            case "星期六": {
              temp = "Saturday";
              break;
            }
            case "星期日": {
              temp = "Sunday";
              break;
            }
          }
          return temp;
        },
        checkVideo(url) {
          // 變更Youtube內嵌網址
          if (url.toLowerCase().includes("https://www.youtube.com/watch?v=")) return url.replace("watch?v=", "embed/").split("&")[0];
          else return url;
        },
        checkEditorBox() {
          let element = document.querySelector(".tox-toolbar__overflow");
          if (element == null) return;
          else element.style.display = "none";
        },
        turnNumber(x) {
          var num = x.toString();
          if (num == "") return 0;
          else if (num == "00" || num == "0 " || (num[0] == "0" && isNaN(num[1]))) return 0;
          return x.replace(/^(0+)|[^\d]+/g, "");
        },
        turnNumberAllowEmpty(x, min) {
          var num = x.toString();
          if (num == "") return num;
          if (x < min) return min;
          else if (num == "00" || num == "0 " || (num[0] == "0" && isNaN(num[1]))) return min;
          return x.replace(/^(0+)|[^\d]+/g, "");
        },
        turnNumber_Min_Max(x, min, max) {
          var num = x.toString();
          if (num == "") return min;
          if (x < min) return min;
          if (x > max) return max;
          else if (num == "00" || num == "0 " || (num[0] == "0" && isNaN(num[1]))) return min;
          return x.replace(/^(0+)|[^\d]+/g, "");
        },
        turnMonthNumber(x) {
          var num = x.toString();
          if (x > 32) return 31;
          if (x < 1) return 1;
          if (num == "") return 0;
          else if (num == "00" || num == "0 " || (num[0] == "0" && isNaN(num[1]))) return 1;
          return x.replace(/^(0+)|[^\d]+/g, "");
        },
        logoutCommon() {
          this.$store.dispatch("auth/logout");
          AuthService.logout();
          this.$router.push("/login");
          this.$store.state.hub.connection.stop();
        },
        setPermission(item) {
          if (item.includes("Write")) return true;
          else return false;
        },
        async isMembershipExpired() {
          try {
            const res = await this.fetchAPI("get", `${process.env.VUE_APP_URL_API}/Member/IsMembershipExpired`);
            console.log(res.data);
            return res.data;
          } catch (err) {
            console.log(err);
            return null;
          }
        },
        async isClassCountLimit() {
          try {
            const res = await this.fetchAPI("get", `${process.env.VUE_APP_URL_API}/Member/IsClassCountLimit`);
            console.log(res.data);
            return res.data;
          } catch (err) {
            console.log(err);
            return null;
          }
        },
        async isMemberLevelUpgradeVerify(memberLevel) {
          try {
            const res = await this.fetchAPI("get", `${process.env.VUE_APP_URL_API}/Member/IsMemberLevelUpgradeVerify?memberLevel=${memberLevel}`);
            console.log(res.data);
            return res.data;
          } catch (err) {
            console.log(err);
            return null;
          }
        },
        async checkMemberDowngrade() {
          try {
            const res = await this.fetchAPI("get", `${process.env.VUE_APP_URL_API}/Member/checkMemberDowngrade`);
            console.log(res.data);
            return res.data;
          } catch (err) {
            console.log(err);
            return null;
          }
        },
        log_PageInfo(pageName, pageType) {
          const vm = this;
          vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/FrontEndLog/PageInfo?pageName=${pageName}&pageType=${pageType}`)
            .then(() => {})
            .catch(err => {
              console.log(err);
            });
        },
        getMemberName(memberId, name) {
          return name == "" ? `會員ID：${memberId}` : name;
        },
        currentUser() {
          var result = {
            avatar: "",
            email: "",
            id: null,
            isEmailVerified: false,
            isFaceBookVerified: false,
            isGoogleVerified: false,
            isLineVerified: false,
            isPhoneVerified: false,
            remindBindLine: true,
            tempRemindBindLine: true,
            memberLevel: "",
            name: "",
            refreshToken: "",
            role: "",
            token: "",
          };
          if (this.$store.state.auth.user != null || this.$store.state.auth.user != undefined) result = this.$store.state.auth.user;
          return result;
        },
        removeUnderscorePrefix(inputString) {
          const parts = inputString.split("_");
          if (parts.length > 1) {
            return parts.slice(1).join("_");
          }
          return inputString;
        },
      },
    });
  },
};
