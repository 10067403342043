export const common = {
  state: {
    instrumentOptions: [],
    teachingAreaOptions: [],
    teachingTypeOptions: [],
    instrumentTypeOptions: [],
    simpleInstrumentOptions: [],
    simpleAreaOptions: [],
    weekOptions: [
      {
        value: "星期一",
        label: "星期一",
      },
      {
        value: "星期二",
        label: "星期二",
      },
      {
        value: "星期三",
        label: "星期三",
      },
      {
        value: "星期四",
        label: "星期四",
      },
      {
        value: "星期五",
        label: "星期五",
      },
      {
        value: "星期六",
        label: "星期六",
      },
      {
        value: "星期日",
        label: "星期日",
      },
    ],
  },
  getters: {},
  mutations: {},
  actions: {},
};
