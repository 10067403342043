import * as signalR from "@aspnet/signalr";

const hubConnection = new signalR.HubConnectionBuilder()
  .withUrl(process.env.VUE_APP_URL_HUB, {
    skipNegotiation: true,
    transport: signalR.HttpTransportType.WebSockets,
  }).build();


export default {
  install(Vue) {
    Vue.config.globalProperties.$hubConnection = hubConnection;
  }
}

