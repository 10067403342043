import axios from "axios";
import Storage from "../models/storage";
import jwt_decode from "jwt-decode";

const API_URL = process.env.VUE_APP_URL_API;

class AuthService {
  login(user) {
    var temp =  Storage.get("referralCode");
    var referralCode =  "";
    if (temp != null && temp != undefined) referralCode = temp.referralCode;

    return axios
      .post(API_URL + `/account/login?ReferralCode=${referralCode}`, {
        account: user.account,
        password: user.password,
      })
      .then(response => {
        return this._processResponse(response);
      });
  }

  logout() {
    var temp =  Storage.get("user");
    var memberId =  "";
    if (temp != null && temp != undefined) memberId = temp.id;
    Storage.remove("user");
    return axios
      .post(API_URL + `/account/logout?memberId=${memberId}`)
      .then(response => {
        return this._processResponse(response);
      });
  }

  register(user) {
    var temp =  Storage.get("referralCode");
    var referralCode =  "";
    if (temp != null && temp != undefined) referralCode = temp.referralCode;

    return axios
      .post(API_URL + `/account/register?ReferralCode=${referralCode}`, {
        email: user.email,
        phone: user.phone,
        password: user.password,
        avatar: user.avatar,
        name: user.name,
        gender: user.gender,
      })
      .then(response => {
        return this._processResponse(response);
      });
  }

  lineLogin(par) {
    var temp =  Storage.get("referralCode");
    var referralCode =  "";
    if (temp != null && temp != undefined) referralCode = temp.referralCode;

    return axios
      .post(API_URL + `/account/login/line?ReferralCode=${referralCode}`, {
        AuthCode: par.AuthCode,
        UserId: par.UserId,
      })
      .then(response => this._processResponse(response));
  }

  googleLogin(par) {
    var temp =  Storage.get("referralCode");
    var referralCode =  "";
    if (temp != null && temp != undefined) referralCode = temp.referralCode;

    return axios
      .post(API_URL + `/account/login/google?ReferralCode=${referralCode}`, {
        AuthCode: par.AuthCode,
        UserId: par.UserId,
      })
      .then(response => this._processResponse(response));
  }

  faceBookLogin(par) {
    var temp =  Storage.get("referralCode");
    var referralCode =  "";
    if (temp != null && temp != undefined) referralCode = temp.referralCode;

    return axios
      .post(API_URL + `/account/login/faceBook?ReferralCode=${referralCode}`, {
        AuthCode: par.AuthCode,
        UserId: par.UserId,
      })
      .then(response => this._processResponse(response));
  }

  _processResponse(response) {
    if (response.data.token) {
      const userInfo = jwt_decode(response.data.token);
      const user = {
        id: userInfo.nameid,
        name: userInfo.name,
        role: userInfo.role,
        avatar: userInfo.avatar,
        email: userInfo.email,
        isLineVerified: userInfo.isLineVerified.toLowerCase() === "true" ? true : false,
        isGoogleVerified: userInfo.isGoogleVerified.toLowerCase() === "true" ? true : false,
        isFaceBookVerified: userInfo.isFaceBookVerified.toLowerCase() === "true" ? true : false,
        isEmailVerified: userInfo.isEmailVerified.toLowerCase() === "true" ? true : false,
        isPhoneVerified: userInfo.isPhoneVerified.toLowerCase() === "true" ? true : false,
        remindBindLine: userInfo.remindBindLine.toLowerCase() === "true" ? true : false,
        memberLevel: userInfo.memberLevel,
        tempRemindBindLine: true,
        token: response.data.token,
        refreshToken: response.data.refreshToken,
      };
      Storage.set("user", user);
      return user;
    }
    return null;
  }
}

export default new AuthService();
