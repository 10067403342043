<template>
	<div class="messageWindow">
		<div class="messageHead">
			<span>訊息</span>
			<span @click="closeMsg" class="closeBtn"
				><i class="fas fa-times"></i
			></span>
		</div>
		<el-tabs
			type="border-card"
			style="padding: 0"
			v-model="activeTab"
			v-show="!isTalk"
		>
			<el-tab-pane label="用戶" name="member">
				<div class="p-2 text-end border-bottom d-none">
					<!-- 先隱藏聊天搜尋 -->
					<div class="d-flex align-items-center">
						<el-input
							class="me-2"
							v-model="filterMemberInput"
							v-if="!searchMemberArea"
							size="small"
							placeholder="篩選用戶"
							@input="filterMember"
						></el-input>
						<div v-else class="text-start d-flex align-items-center w-100">
							<el-autocomplete
								class="inline-input me-2"
								v-model="searchMemberInput"
								:fetch-suggestions="querySearch"
								placeholder="尋找用戶"
								:trigger-on-focus="false"
								@select="handleSelectMember"
								size="small"
							>
								<template v-slot="{ item }">
									<div class="w-100 d-flex align-items-center py-2">
										<el-avatar size="small" :src="item.avatar" />
										<div class="ms-2">
											<div style="line-height: normal">
												<span class="d-block">{{ item.name }}</span>
												<span>{{ item.email }}</span>
											</div>
										</div>
									</div>
								</template>
							</el-autocomplete>
							<!-- <el-button size="small" type="success" @click="sendMessage"
                >加入</el-button
              > -->
						</div>

						<el-button
							v-if="!searchMemberArea"
							size="small"
							class="ms-auto fal fa-comment-plus"
							@click="searchMemberArea = !searchMemberArea"
							><span class="ms-1">找用戶</span></el-button
						>
						<el-button
							v-else
							size="small"
							class="ms-auto fal fa-search"
							@click="searchMemberArea = !searchMemberArea"
						>
							<span class="ms-1">回篩選</span>
						</el-button>
					</div>
				</div>
				<div class="messageList">
					<ul class="list-group list-group-flush">
						<li
							v-for="index in messageList_member"
							:key="index"
							@click="
								toMessage(
									index,
									index.messageGroupId,
									index.targetId,
									index.classRoomId
								)
							"
							class="memberArea list-group-item"
						>
							<div class="avatarArea">
								<div v-if="index.avatar == ''" class="avatar">
									<i class="fa-solid fa-circle-user"></i>
								</div>
								<img v-else class="avatar" :src="index.avatar" alt="avatar" />
								<div class="OnlineLight" v-show="index.isOnline"></div>
							</div>
							<div class="memberInfo">
								<p class="memberName d-flex">
									<span class="chatName">{{ index.targetName }}</span>
									<span class="lastMsgTime">{{
										index.latestSendDateTime
									}}</span>
								</p>
								<p
									v-if="
										this.$store.state.auth.user.id != index.senderId &&
										!index.isRead
									"
									v-html="index.latestContent"
									class="messageTitle fw-bold"
								></p>
								<p v-else v-html="index.latestContent" class="messageTitle"></p>
							</div>

							<div
								class="IsNotRead"
								v-if="
									this.$store.state.auth.user.id != index.senderId &&
									!index.isRead
								"
							></div>
						</li>
					</ul></div
			></el-tab-pane>
			<el-tab-pane label="課堂" name="classGroup">
				<div class="messageList">
					<ul class="list-group list-group-flush">
						<li
							v-for="index in messageList_classGroup"
							:key="index"
							@click="
								toMessage(
									index,
									index.messageGroupId,
									index.targetId,
									index.classRoomId
								)
							"
							class="memberArea list-group-item"
						>
							<div class="avatarArea">
								<div v-if="index.avatar == ''" class="avatar">
									<i class="fa-solid fa-circle-user"></i>
								</div>
								<img v-else class="avatar" :src="index.avatar" alt="avatar" />
								<div class="OnlineLight" v-show="index.isOnline"></div>
							</div>
							<div class="memberInfo">
								<p class="memberName d-flex">
									<span class="chatName">{{ index.targetName }}</span>
									<span class="lastMsgTime">{{
										index.latestSendDateTime
									}}</span>
								</p>
								<p
									v-if="
										this.$store.state.auth.user.id != index.senderId &&
										!index.isRead
									"
									v-html="index.latestContent"
									class="messageTitle fw-bold"
								></p>
								<p v-else v-html="index.latestContent" class="messageTitle"></p>
							</div>
							<div
								class="IsNotRead"
								v-if="
									this.$store.state.auth.user.id != index.senderId &&
									!index.isRead
								"
							></div>
						</li>
					</ul></div
			></el-tab-pane>
			<!-- 先隱藏群組 -->
			<!-- <el-tab-pane label="群組" name="privateGroup" class="position-relative">
        <div class="p-2 text-end border-bottom position-sticky">
          <el-button
            v-if="!addPrivateGroup"
            size="small"
            class="fal fa-users-medical"
            @click="addPrivateGroup = !addPrivateGroup"
          >
            <span class="ms-1">建立群組</span>
          </el-button>
          <el-button
            v-else
            size="small"
            class="fal fa-times"
            @click="addPrivateGroup = !addPrivateGroup"
          >
            <span class="ms-1">取消</span>
          </el-button>

          <el-collapse-transition>
            <div v-show="addPrivateGroup">
              <div class="p-2">
                <el-input
                  class="me-2 mb-2"
                  v-model="privateGroupNameInput"
                  size="small"
                  placeholder="輸入群組名稱(選填)"
                ></el-input>
                <div class="text-start d-flex align-items-center">
                  <el-autocomplete
                    class="inline-input me-2"
                    v-model="searchMemberInput_Group"
                    :fetch-suggestions="querySearch"
                    placeholder="搜尋用戶"
                    :trigger-on-focus="false"
                    @select="handleSelectMember_Group"
                    size="small"
                  >
                    <template v-slot="{ item }">
                      <div class="w-100 d-flex align-items-center py-2">
                        <el-avatar size="small" :src="item.avatar" />
                        <div class="ms-2">
                          <div style="line-height: normal">
                            <span class="d-block">{{ item.name }}</span>
                            <span>{{ item.email }}</span>
                          </div>
                        </div>
                      </div>
                    </template>
                  </el-autocomplete>
                  <el-button
                    size="small"
                    class="fal fa-plus"
                    @click="addPrivateGroupFunc"
                  >
                    <span class="ms-1">建立</span>
                  </el-button>
                </div>

                <p
                  class="border-bottom text-start text-dark py-1"
                  style="font-size: xx-small"
                >
                  成員
                </p>
                <div
                  class="py-3 d-flex align-items-center flex-wrap justify-content-start"
                >
                  <div
                    v-if="this.$store.state.auth.user != null"
                    class="text-center mx-1 mb-2 position-relative"
                    style="width: 50px"
                  >
                    <el-avatar
                      class="me-2"
                      :src="currentUser().avatar"
                    ></el-avatar>
                    <p
                      class="text-dark text-truncate"
                      style="font-size: xx-small"
                    >
                      {{ currentUser().name }}
                    </p>
                  </div>
                  <template v-for="m in privateGroupMembers" :key="m">
                    <div
                      class="text-center mx-1 mb-2 position-relative"
                      style="width: 50px"
                      @click="removeGroupMember(m)"
                    >
                      <el-avatar class="me-2" :src="m.avatar"></el-avatar>
                      <p
                        class="text-dark text-truncate"
                        style="font-size: xx-small"
                      >
                        {{ m.targetName }}
                      </p>
                      <div class="removeGroupMember">x</div>
                    </div>
                  </template>
                </div>
                <p
                  class="border-bottom text-start text-dark pb-1"
                  style="font-size: xx-small"
                >
                  建議(聊天用戶)
                </p>
                <ul class="list-group list-group-flush">
                  <li
                    class="list-group-item list-group-item-action d-flex align-items-center suggestMember"
                    v-for="index in messageList_member"
                    :key="index"
                    @click="addPrivateGroupMember(index)"
                  >
                    <el-avatar
                      class="me-2"
                      size="small"
                      :src="index.avatar"
                    ></el-avatar>
                    <span>{{ index.targetName }}</span>
                    <i
                      v-if="
                        privateGroupMembers.some((x) => {
                          return x.targetId === index.targetId;
                        })
                      "
                      class="ms-auto fal fa-check-circle text-success"
                    ></i>
                    <i v-else class="ms-auto fal fa-circle"></i>
                  </li>
                </ul>
              </div>
            </div>
          </el-collapse-transition>
        </div>
        <div class="messageList">
          <ul class="list-group list-group-flush">
            <li
              v-for="index in messageList_privateGroup"
              :key="index"
              @click="
                toMessage(
                  index,
                  index.messageGroupId,
                  index.targetId,
                  index.classRoomId
                )
              "
              class="memberArea list-group-item"
            >
              <div class="avatarArea">
                <div v-if="index.avatar == ''" class="avatar">
                  <i class="far fa-users fs-3"></i>
                </div>
                <img v-else class="avatar" :src="index.avatar" alt="avatar" />
                <div class="OnlineLight" v-show="index.isOnline"></div>
              </div>
              <div class="memberInfo">
                <p class="memberName d-flex">
                  <span class="chatName">{{ index.targetName }}</span>
                  <span class="lastMsgTime">{{
                    index.latestSendDateTime
                  }}</span>
                </p>
                <p
                  v-if="
                    this.$store.state.auth.user.id != index.senderId &&
                    !index.isRead
                  "
                  v-html="index.latestContent"
                  class="messageTitle fw-bold"
                ></p>
                <p v-else v-html="index.latestContent" class="messageTitle"></p>
              </div>
              <div
                class="IsNotRead"
                v-if="
                  this.$store.state.auth.user.id != index.senderId &&
                  !index.isRead
                "
              ></div>
            </li>
          </ul>
        </div>
      </el-tab-pane> -->
		</el-tabs>

		<div class="memberMessage" v-show="isTalk">
			<div class="messageHead">
				<span class="back" @click="leaveChat"
					><i class="fas fa-chevron-left me-1"></i
				></span>
				<span class="memberName d-flex align-items-center">
					<el-avatar size="small" :src="msgTargetInfo.targetAvatar"></el-avatar>
					<span class="ms-1 chatName" style="width: 200px" for="">{{
						msgTargetInfo.name
					}}</span>
				</span>
				<span @click="closeMsg" class="closeBtn"
					><i class="fas fa-times"></i
				></span>
			</div>
			<div
				class="border-bottom text-start bg-light"
				v-if="activeTab != 'member'"
			>
				<div
					class="p-1 px-2 d-flex shadow shadow-sm"
					@click="groupMemberList = !groupMemberList"
				>
					<span>成員</span>
					<i v-if="!groupMemberList" class="ms-auto far fa-caret-down"></i>
					<i v-else class="ms-auto far fa-caret-up"></i>
				</div>

				<el-collapse-transition>
					<div v-show="groupMemberList">
						<ul class="list-group list-group-flush text-start memberList">
							<li
								class="list-group-item d-flex align-items-center"
								v-for="m in msgTargetInfo.groupMembers"
								:key="m"
							>
								<el-avatar
									class="me-2"
									size="small"
									:src="m.memberAvatar"
								></el-avatar>
								<span>{{ m.memberName }}</span>
								<span class="roleBadge" v-for="r in m.classRole" :key="r">
									{{ roleToChinese(r) }}</span
								>
								<i
									v-if="this.$store.state.auth.user.id != m.memberId"
									@click="
										activeTab = 'member';
										messageInfo.messages = [];
										createMessageGroup(Number(m.memberId));
									"
									class="fad fa-comment-lines ms-auto"
									style="
										--fa-primary-color: #12af15;
										--fa-secondary-color: #12af15;
									"
								></i>
							</li>
						</ul>
					</div>
				</el-collapse-transition>
			</div>
			<div class="chatMessage" id="chatBox" @mousewheel="handleScroll">
				<div
					v-for="(item, index) in messageInfo.messages"
					:key="index"
					class="memberIntroduction d-flex align-items-end"
					:class="{
						'flex-row-reverse': item.senderId == this.$store.state.auth.user.id,
					}"
				>
					<img
						v-if="item.senderMemberAvatar != ''"
						class="avatar"
						:src="item.senderMemberAvatar"
						alt="avatar"
					/>
					<div v-else class="avatar">
						<i class="fa-solid fa-circle-user"></i>
					</div>

					<div
						class="px-2"
						:class="{
							'text-end': item.senderId == this.$store.state.auth.user.id,
							'text-start': item.senderId != this.$store.state.auth.user.id,
						}"
					>
						<p
							v-if="activeTab != 'member'"
							class="mb-1 d-flex"
							style="font-size: xx-small"
							:class="{
								'flex-row-reverse':
									item.senderId == this.$store.state.auth.user.id,
							}"
						>
							{{ item.senderName }}
							<span>
								<span class="roleBadge" v-for="r in item.classRole" :key="r">
									{{ roleToChinese(r) }}
								</span>
							</span>
						</p>
						<div
							class="d-flex align-items-end"
							:class="{
								'flex-row-reverse':
									item.senderId == this.$store.state.auth.user.id,
							}"
						>
							<span
								class="messageBubble"
								:class="{
									userMsgStyle: item.senderId == this.$store.state.auth.user.id,
								}"
								v-html="item.content"
							></span>
							<span class="msgTime mx-2">
								<p
									v-if="
										item.senderId == this.$store.state.auth.user.id &&
										!item.isRead &&
										activeTab == 'member'
									"
								>
									未讀
								</p>
								<p
									v-if="
										item.senderId == this.$store.state.auth.user.id &&
										item.isRead &&
										activeTab == 'member'
									"
								>
									已讀
								</p>
								<span>{{ item.sendDateTime }}</span>
							</span>
						</div>
					</div>
				</div>
			</div>

			<div class="messageInput">
				<div class="bg-white messageInputChild">
					<textarea
						maxrows="4"
						fontsize="14"
						id="input-24551"
						placeholder="說些什麼...（請使用Shift+Enter換行）"
						rows="1"
						style=""
						@keydown.enter.prevent="sendMsg"
						v-model="message"
					></textarea>
					<div class="send-btn" @click="sendMsg">
						<i class="fas fa-paper-plane"></i>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Storage from "../../../models/storage";

export default {
	data() {
		return {
			privateGroupNameInput: "",
			searchMemberInput_Group: "",
			privateGroupMembers: [],
			addPrivateGroup: false,
			groupMemberList: false,
			filterMemberInput: "",
			members: { memberData: [] },
			searchMemberInput: "",
			searchMemberArea: false,
			selectMember: "",
			activeTab: "member",
			targetAvatar: "",
			targetGroupId: "",
			message: "",
			messageList_member: [],
			original_messageList_member: [],

			messageList_classGroup: [],
			messageList_privateGroup: [],
			isTalk: false,
			msgTargetInfo: {},
			messageInfo: {
				messages: [], // 初始化為空數組
				nextCursor: null, // 初始化為null
			},
		};
	},
	methods: {
		addPrivateGroupFunc() {
			let vm = this;
			let memberId = Storage.get("user").id;
			let groupMemberIds = vm.privateGroupMembers.map((x) => {
				return x.targetId;
			});
			let roomName = vm.privateGroupNameInput;
			vm.$hubConnection
				.invoke(
					"CreatePrivateGroup",
					Number(memberId),
					groupMemberIds,
					roomName
				)
				.then(() => {
					vm.notify("success", `建立完成!`);
					vm.privateGroupNameInput = "";
					vm.privateGroupMembers = [];
					vm.addPrivateGroup = false;
					vm.$hubConnection
						.invoke("GetMemberMessageGroups_Private", Number(memberId))
						.then((res) => {
							vm.messageList_privateGroup = res;
						});
				});
		},
		removeGroupMember(member) {
			let vm = this;

			vm.privateGroupMembers = vm.privateGroupMembers.filter(
				(x) => x.targetId != member.targetId
			);
		},
		addPrivateGroupMember(member) {
			let vm = this;
			let memberId = Storage.get("user").id;
			if (member.targetId == memberId) {
				vm.notify("error", `群組已包含自己!`);
				return;
			}

			let isMemberAlreadyAdded = vm.privateGroupMembers.some(
				(existingMember) => {
					return existingMember.targetId === member.targetId;
				}
			);

			if (!isMemberAlreadyAdded) {
				vm.privateGroupMembers.push(member);
			}
		},
		roleToChinese(role) {
			if (role.toLowerCase() == "teacher") {
				return "老師";
			}
			if (role.toLowerCase() == "student") {
				return "學生";
			}
			if (role.toLowerCase() == "owner") {
				return "建立者";
			}
			return "家長";
		},
		filterMember() {
			let vm = this;
			if (vm.filterMemberInput != "") {
				vm.messageList_member = vm.original_messageList_member.filter((item) =>
					item.targetName
						.toLowerCase()
						.includes(vm.filterMemberInput.toLowerCase())
				);
			} else {
				vm.messageList_member = vm.original_messageList_member;
			}
		},
		handleSelectMember_Group(item) {
			let vm = this;
			let member = {
				avatar: item.avatar,
				targetName: item.name,
				targetId: item.memberId,
			};
			vm.addPrivateGroupMember(member);
			vm.searchMemberInput_Group = "";
		},
		handleSelectMember(item) {
			let vm = this;
			vm.selectMember = item;
			vm.sendMessage();
		},
		createMessageGroup(targetId) {
			var vm = this;
			let memberId = Storage.get("user").id;

			vm.$hubConnection
				.invoke("CreateOneToOneGroup", Number(memberId), Number(targetId))
				.then((res) => {
					vm.targetGroupId = res.memberMessageGroupId;
					this.msgTargetInfo = {
						Id: res.memberMessageGroupId,
						name: res.targetName,
						targetId: res.targetId,
						targetAvatar: res.targetAvatar,
					};
					vm.enterGroup(vm.targetGroupId, Number(memberId));
					vm.getPreviousMessages(Number(vm.targetGroupId), "");
				});
		},
		//點擊進入聊天畫面
		toMessage(obj, messageGroupId, targetId, classRoomId) {
			if (messageGroupId == null || messageGroupId == "") messageGroupId = -1;
			var vm = this;
			vm.isTalk = true;
			let memberId = Storage.get("user").id;
			vm.targetGroupId = messageGroupId;

			if (vm.activeTab == "member") {
				vm.createMessageGroup(Number(targetId));
			} else if (vm.activeTab == "classGroup") {
				vm.$hubConnection
					.invoke("CreateClassGroup", Number(memberId), Number(classRoomId))
					.then((res) => {
						this.$store.state.targetGroupId = res.memberMessageGroupId;
						vm.targetGroupId = res.memberMessageGroupId;
						this.msgTargetInfo = {
							Id: res.memberMessageGroupId,
							name: res.targetName,
							targetId: res.targetId,
							targetAvatar: res.targetAvatar,
							groupMembers: res.groupMembers,
						};
						console.log(this.msgTargetInfo);
						vm.enterGroup(vm.targetGroupId, Number(memberId));
						vm.getPreviousMessages(Number(vm.targetGroupId), "");
					});
			} else {
				vm.msgTargetInfo = {
					Id: obj.messageGroupId,
					name: obj.messageGroupName,
					targetId: 0,
					groupMembers: obj.groupMembers,
				};
				vm.enterGroup(vm.targetGroupId, Number(memberId));
				vm.getPreviousMessages(Number(vm.targetGroupId), "");
			}
		},
		getPreviousMessages(Id, cursor) {
			let vm = this;
			vm.$hubConnection
				.invoke("GetPreviousMessages", Number(Id), cursor)
				.then((res) => {
					if (cursor != "" && cursor != null) {
						vm.messageInfo.messages = res.messages.concat(
							vm.messageInfo.messages
						);
					} else {
						vm.messageInfo.messages = res.messages;
						this.toChatBottom();
					}
					vm.messageInfo.nextCursor = res.nextCursor;
				});
		},
		enterGroup(gId) {
			let vm = this;
			let memberId = Storage.get("user").id;
			vm.$hubConnection
				.invoke("enterGroup", gId, Number(memberId))
				.then((res) => {
					vm.msgTargetInfo.groupMembers = res;
				});
		},
		sendMsg(e) {
			const vm = this;
			if (e.shiftKey && e.keyCode == 13) {
				vm.message += "\n";
			} else {
				if (vm.message !== "") {
					let memberId = Storage.get("user").id;
					vm.$hubConnection
						.invoke(
							"sendMessage",
							Number(vm.targetGroupId),
							Number(memberId),
							vm.message
						)
						.then((res) => {
							vm.messageInfo.messages.push(res);
							vm.toChatBottom();
						});
				}
				vm.message = "";
			}
		},
		toChatBottom() {
			this.$nextTick(() => {
				let scrollDom = document.getElementById("chatBox");
				scrollDom.scrollTop = scrollDom.scrollHeight;
			});
		},
		closeMsg() {
			this.$store.commit("CreateMessageGroup", "");
			this.$store.commit("CloseMessageWin");
			this.isTalk = false;
			this.activeTab = "member";
		},
		handleScroll() {
			let vm = this;
			let chatBox_scrollTop = document.querySelector("#chatBox").scrollTop;
			if (chatBox_scrollTop == 0) {
				vm.getPreviousMessages(
					Number(vm.targetGroupId),
					vm.messageInfo.nextCursor
				);
			}
		},
		leaveChat() {
			let vm = this;
			vm.isTalk = false;
			vm.messageInfo.messages = "";
		},
		messageIsReads() {
			let vm = this;
			if (vm.activeTab == "member") {
				vm.messageInfo.messages.forEach((element) => {
					element.isRead = true;
				});
			}
		},
		getMemberMessageGroup() {
			let vm = this;
			let memberId = Storage.get("user").id;

			if (vm.activeTab == "member") {
				vm.$hubConnection
					.invoke("GetMessageGroup", Number(memberId))
					.then((res) => {
						vm.messageList_member = res;
						vm.original_messageList_member = res;
					});
			} else if (vm.activeTab == "classGroup") {
				vm.$hubConnection
					.invoke("GetMemberMessageGroups_Class", Number(memberId))
					.then((res) => {
						vm.messageList_classGroup = res;
					});
			} else {
				vm.$hubConnection
					.invoke("GetMemberMessageGroups_Private", Number(memberId))
					.then((res) => {
						vm.messageList_privateGroup = res;
					});
			}
		},
		querySearch(queryString, cb) {
			var results = queryString
				? this.members.memberData.filter(this.createFilter(queryString))
				: this.members.memberData;
			cb(results);
		},
		createFilter(queryString) {
			return (r) => {
				return (
					r.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0 ||
					r.email.toLowerCase().indexOf(queryString.toLowerCase()) === 0
				);
			};
		},
		loadAllMember() {
			const vm = this;
			vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/Member/all`)
				.then((res) => {
					res.data.forEach((x) => {
						vm.members.memberData.push({
							value: vm.getMemberName(x.memberId, x.name),
							memberId: x.memberId,
							name: vm.getMemberName(x.memberId, x.name),
							nickName: x.nickName,
							avatar: x.avatar,
							role: x.role,
							email: x.email,
						});
					});
				})
				.catch((err) => {
					console.log(err);
				});
		},
		sendMessage() {
			let vm = this;
			let selectMemberId = vm.selectMember.memberId;
			let memberId = Storage.get("user").id;
			if (selectMemberId == memberId) {
				vm.notify("error", `不能傳訊息給自己!`);
				this.searchMemberInput = "";
				return;
			}
			this.$store.state.isTalk = true;
			this.$store.commit("OpenMessageWin");
			this.$store.commit("CreateMessageGroup", selectMemberId);
			this.searchMemberInput = "";
		},
	},
	watch: {
		activeTab() {
			let vm = this;
			vm.getMemberMessageGroup();
		},
		"$store.getters.isMessageShow"(newVal, oldVal) {
			let vm = this;
			if (newVal) {
				vm.getMemberMessageGroup();
			} else {
				vm.groupMemberList = false;
			}
			console.log(oldVal);
		},
		"$store.getters.CreateGroupName"(newVal, oldVal) {
			var vm = this;
			if (newVal != null && newVal != "") {
				vm.isTalk = true;
				vm.createMessageGroup(newVal);
			} else {
				console.log(oldVal);
			}
		},
		isTalk(newVal, oldVal) {
			let vm = this;
			if (newVal == false) {
				vm.getMemberMessageGroup();
				console.log(oldVal);
				vm.groupMemberList = false;
			}
		},
	},
	async created() {
		let vm = this;
		await vm.loadAllMember();
	},
	mounted() {
		let vm = this;
		if (vm.$store.state.auth.status.loggedIn) {
			//如果視窗是開的
			if (vm.$store.getters.isMessageShow == true) {
				vm.getMemberMessageGroup();
			}
			//監聽已讀
			let memberId = Storage.get("user").id;
			vm.$hubConnection.on("MessageIsRead", () => {
				vm.messageIsReads();
			});
			//監聽通知(沒開窗才通知) 有開窗(顯示已讀)
			vm.$hubConnection.on("CallMessageNotice", (result) => {
				if (!vm.$store.getters.isMessageShow) {
					const bytes = new Uint8Array(
						atob(result.noticeMsg)
							.split("")
							.map((char) => char.charCodeAt(0))
					);
					const textDecoder = new TextDecoder("big5");
					const big5Text = textDecoder.decode(bytes);
					vm.notify("info", big5Text, true);
				} else {
					if (vm.isTalk && vm.targetGroupId == result.groupId) {
						vm.$hubConnection.invoke(
							"TargetMemberIsRead",
							Number(vm.targetGroupId),
							Number(memberId)
						);
					}
					vm.getMemberMessageGroup();
				}
			});

			vm.$hubConnection.on("ReceiveMessage", (result) => {
				if (vm.isTalk && vm.targetGroupId == result.groupId) {
					vm.messageInfo.messages.push(result);
					vm.toChatBottom();
					vm.$hubConnection.invoke(
						"TargetMemberIsRead",
						Number(vm.targetGroupId),
						Number(memberId)
					);
				} else {
					vm.getMemberMessageGroup();
				}
			});
		}
	},
	beforeUnmount() {
		let vm = this;
		if (vm.$store.state.auth.status.loggedIn) {
			vm.$hubConnection.off("MessageIsRead");
			vm.$hubConnection.off("CallMessageNotice");
			vm.$hubConnection.off("ReceiveMessage");
		}
	},
};
</script>

<style lang="scss" scoped>
.memberList {
	max-height: 30vh;
	overflow: auto;
}
.memberList .list-group-item {
	background-color: #f8f9fa !important;
}
.roleBadge {
	border-radius: 10px;
	font-size: xx-small;
	background-color: #b2deff;
	padding: 0 4px;
	margin-right: 4px;
	margin-left: 4px;
	color: black;
}
:deep .el-tabs__content {
	padding: 0 !important;
	height: calc(100vh - 190px);
	overflow: auto !important;
}
.fa-user-plus:hover {
	cursor: pointer;
	transform: scale(1.05);
}
.memberArea {
	cursor: pointer;
	position: relative;
	padding: 0.5rem;
	display: flex;
	align-items: center;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
	&:hover {
		background: #e6e6e6;
	}
	.IsNotRead {
		position: absolute;
		width: 12px;
		height: 12px;
		border-radius: 99em;
		background: #ff5f5f;
		bottom: 10px;
		right: 10px;
	}
	.memberInfo {
		text-align: start;
		padding-left: 1rem;
		overflow: hidden;
		display: flex;
		width: 320px;
		flex-wrap: wrap;
		flex-direction: column;
		.memberName {
			// font-size: 20px;
			margin-bottom: 5px;
		}
		.messageTitle {
			color: #8d8c8c;
			width: 100%;
			width: 280px;
			overflow: hidden;
			font-size: 14px;
			text-overflow: ellipsis;
			white-space: nowrap;
			height: 1rem;
		}
	}
}
.avatar {
	width: 2.5rem;
	height: 2.5rem;
	border-radius: 50%;
	object-fit: cover;
	box-shadow: 1px 1px 3px 1px $main_gray;
	i {
		font-size: 2.5rem;
		line-height: 2.5rem;
	}
}
.avatarArea {
	position: relative;
	.avatar {
		width: 2.5rem;
		height: 2.5rem;
		border-radius: 50%;
		object-fit: cover;
		box-shadow: 1px 1px 3px 1px $main_gray;
	}
	.OnlineLight {
		position: absolute;
		height: 0.8rem;
		width: 0.8rem;
		background-color: rgb(0, 236, 63);
		border-radius: 99em;
		bottom: 0;
		right: 0;
		border: 2px white solid;
	}
}
.removeGroupMember {
	position: absolute;
	height: 1rem;
	width: 1rem;
	background-color: BLACK;
	border-radius: 99em;
	top: 0;
	right: 0;
	font-size: 0.5rem;
	line-height: 0.7rem;
	color: white;
	border: 2px white solid;
}
.messageWindow {
	position: fixed;
	bottom: 0;
	right: 2vw;
	width: 380px;
	height: calc(100vh - 90px);
	background-color: white;
	z-index: 99997;
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 8px 8px 0px 0px;
	box-shadow: 6px 6px 8px rgba(0, 0, 0, 0.1);
	overflow: hidden;
	.memberMessage {
		position: absolute;
		top: 0;
		bottom: 0;
		width: 380px;
		height: calc(100vh - 90px);
		background-color: white;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		z-index: 999;
		.chatMessage {
			background-color: #ffffff;
			overflow-y: scroll;
			flex-grow: 1;
		}
		.memberIntroduction {
			// padding: 0.2rem;

			background-color: #ffffff;
			display: flex;
			align-items: center;
			padding: 0.5rem;
			.messageBubble {
				text-align: left;
				word-break: break-all;
				display: inline-block;
				max-width: 240px;
				background-color: #f5f5f5;
				// border: 1px solid rgb(189, 189, 189);
				border-radius: 4px;
				padding: 8px;
				white-space: pre-line;
				overflow-wrap: break-word; /* 新增這行 */
			}
			.userMsgStyle {
				background-color: $main_color;
			}
			.msgTime {
				text-align: right;
				font-size: xx-small;
			}
		}
		.messageInput {
			background: #f5f5f5;
			bottom: 0;
			width: 100%;
			padding: 0.5rem;
			border: 1px solid rgb(189, 189, 189);
			.messageInputChild {
				border-radius: 8px;
				overflow: hidden;
				padding: 0.5rem;
			}
			textarea {
				width: 100%;
				height: 40px;
				max-height: 150px;
				resize: none;
				border: none;

				// padding: 0.4rem;
				&:focus {
					border: none;
					outline: none;
				}
			}
			.send-btn {
				width: 100%;
				text-align: right;
				.fa-paper-plane {
					cursor: pointer;
					color: $main_color;
				}
			}
		}
	}

	.messageHead {
		display: flex;
		justify-content: space-between;
		align-items: center;
		// background-color: $main_color;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		padding: 1rem 1rem;
		.memberName {
			display: inline-block;
			overflow: hidden;
			white-space: nowrap;
			justify-content: center;
			text-align: center;
			align-items: center;
		}
		.back {
			cursor: pointer;
		}
		.closeBtn {
			cursor: pointer;
			display: inline-block;
			width: 30px;
			height: 30px;
			padding: 4px;
			border-radius: 99em;
			line-height: 1.5rem;
			&:hover {
				background: #e6e6e6;
			}
		}
	}
	.chatName {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 150px;
	}
	.lastMsgTime {
		margin-left: auto;
		font-size: 14px;
		color: #aeaeae;
	}
}

@media screen and (max-width: 768px) {
	:deep .el-tabs {
		margin-bottom: auto;
		overflow: auto;
	}
	:deep .el-tabs__header {
		position: sticky;
		top: 0;
		left: 0;
		right: 0;
		z-index: 1000;
	}
	:deep .el-tabs__content {
		padding: 0 !important;
		height: inherit;
		overflow: auto !important;
	}

	.messageWindow {
		// overflow: auto;
		display: flex;
		flex-direction: column;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: auto;
		margin: auto;
		height: 100%;
		.memberArea {
			.memberInfo {
				width: 100%;
				.messageTitle {
					width: 100%;
				}
			}
		}
		.memberMessage {
			height: calc(90vh) !important;
			width: 100%;
		}
	}

	.messageHead {
		// background-color: $main_color;
		top: 0;
		width: 100%;
	}
	// .chatMessage {
	//   margin-bottom: 155px;
	// }
	.messageList ul {
		overflow: auto;
	}
	.messageInput {
		border-radius: 0 0 8px 8px;
		margin-bottom: 2rem;
		bottom: 0 !important;
	}
}
</style>
